body{
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 16px;
	font-weight: 300;
	color: #000;
}

h1, h2, h3, h4, h5, h6{
	font-weight: 300;
	text-transform: uppercase;
}

strong{
	font-weight: 400;
}

::selection {
	background: $brand-primary;
	@include opacity(1);
}
::-moz-selection {
	background: $brand-primary;
	@include opacity(1);
}

.btn{
	@include border-top-radius(0);
	@include border-bottom-radius(0);

	&.btn-primary{
		font-weight: 600;
		text-transform: uppercase;
		color: #000;
	}
}

.wrapper{
	position: relative;
	min-height: calc(100vh - 45px);
	margin: 15px 15px 30px 15px;
	background: #fcfcfc;

	&:before{
		content: '';
		position: absolute;
		top: 0; left: 0;
		z-index: 10;
		display: block;
		width: 100%; height: 100%;
		background: url('../images/people.jpg') center center no-repeat;
		background-size: cover;
	}
	&:after{
		content: '';
		position: absolute;
		top: 0; left: 0;
		z-index: 10;
		display: block;
		width: 100%; height: 100%;
		background: rgba(#f9f9f9, 0.9);
	}
	.header{
		position: relative;
		z-index: 110;
		padding: 30px 15px;

		.start-a-project{
			position: absolute;
			top: 50%; right: 15px;
			padding-bottom: 10px;
			font-size: 1.000em;
			font-weight: 600;
			text-transform: uppercase;
			color: #000;
			-ms-transform: translate(0, -50%);
			-webkit-transform: translate(0, -50%);
			transform: translate(0, -50%);

			&:before{
				content: '';
				position: absolute;
				bottom: 0; left: 0;
				z-index: -1;
				display: block;
				width: 100%; height: 4px;
				background: $brand-primary;

				-webkit-transition: .3s ease-in-out;
				-moz-transition: .3s ease-in-out;
				-o-transition: .3s ease-in-out;
				transition: .3s ease-in-out;
			}
			&:hover, &:focus{
				text-decoration: none;
			}
			&:hover{
				&:before{
					bottom: 50%;
					-ms-transform: translate(0, -3px);
					-webkit-transform: translate(0, -3px);
					transform: translate(0, -3px);
				}
			}
		}
	}
	.content{
		position: absolute;
		z-index: 100;
		top: 50%; left: 0;
		width: 100%;
		padding: 15px;
		text-align: center;
		-ms-transform: translate(0, -50%);
		-webkit-transform: translate(0, -50%);
		transform: translate(0, -50%);

		.btn{
			padding: 10px 60px;
			margin-top: 15px;
			font-size: 1.000em;
		}
		h1{
			position: relative;
			display: inline-block;
			margin: 0;
			font-size: 3.000em;

			&:before{
				content: '';
				position: absolute;
				top: 50%; left: -5px;
				z-index: -1;
				width: calc(100% + 10px); height: 35%;
				background: $brand-primary;
				-ms-transform: translate(0, -45%);
				-webkit-transform: translate(0, -45%);
				transform: translate(0, -45%);
			}
		}
		p{
			margin: 10px 0 0 0;
			font-size: 1.500em;
			font-weight: 200;
		}
	}
	.content-bottom{
		position: absolute;
		z-index: 100;
		bottom: 0; left: 0;
		width: 100%;
		padding: 15px;
		text-align: center;

		.services{
			> ul{
				padding: 0;
				margin: 0;
				list-style: none;

				> li{
					display: inline-block;

					&+li:before{
						padding: 0 5px;
						color: #ccc;
						content: "/\00a0";
					}
				}
			}
		}
	}
	.footer{
		position: absolute;
		left: 0; top: 100%;
		z-index: 110;
		width: calc(100% + 30px); height: 30px;
		margin: 0 -15px;
		font-size: 0.750em;
		line-height: 30px;
		background: #fff;

		.company{
			a{
				display: inline-block;
				margin-left: 15px;
				color: #000;
				outline: none;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:hover{
					color: darken($brand-primary, 30%);
				}
			}
		}
		.share{
			text-align: right;

			a{
				color: #000;
				outline: none;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:hover{
					color: darken($brand-primary, 30%);
				}
				.glyphicon{
					margin-right: 3px;
					color: #c53b2b;
				}
			}
		}
	}
}

.modal-backdrop{
	background-color: $brand-primary;

	&.in{
		@include opacity(0.95);
	}
}
.modal{
	text-align: center;
	padding: 0 !important;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -4px;
	}
	.modal-dialog {
		display: inline-block;
		text-align: left;
		vertical-align: middle;
	}
	.modal-content{
		background: none;
		border: none;
		@include border-top-radius(0);
		@include border-bottom-radius(0);
		box-shadow: none;

		::selection {
			background: #fff;
			@include opacity(1);
		}
		::-moz-selection {
			background: #fff;
			@include opacity(1);
		}
		a{
			color: #fff;
		}
		.modal-header{
			text-align: center;
			border-bottom: none;

			.modal-title{
				position: relative;
				display: inline-block;
				font-size: 2.000em;

				&:before{
					content: '';
					position: absolute;
					top: 50%; left: -5px;
					z-index: -1;
					width: calc(100% + 10px); height: 35%;
					background: #fff;
					-ms-transform: translate(0, -45%);
					-webkit-transform: translate(0, -45%);
					transform: translate(0, -45%);
				}
			}
		}
		.modal-body{
			font-weight: 200;
			text-align: center;
		}
	}
}

.social-icon{
	display: inline-block;
	width: 64px; height: 64px;
	margin: 10px;
	font-size: 36px;
	line-height: 64px;
	background: #ccc;

	&.facebook{
		background-color: #3b5998;

		&:hover{
			background-color: lighten(#3b5998, 10%);
		}
	}
	&.linkedin{
		background-color: #007ab5;

		&:hover{
			background-color: lighten(#007ab5, 10%);
		}
	}
	&.twitter{
		background-color: #33ccff;

		&:hover{
			background-color: lighten(#33ccff, 10%);
		}
	}
	&.telegram{
		background-color: #2fade6;

		&:hover{
			background-color: lighten(#2fade6, 10%);
		}
	}
	&.viber{
		background-color: #7c529d;

		&:hover{
			background-color: lighten(#7c529d, 10%);
		}
	}
	&.whatsapp{
		background-color: #48c234;

		&:hover{
			background-color: lighten(#48c234, 10%);
		}
	}
}

.cc-revoke, .cc-window{
	font-family: 'Source Sans Pro', sans-serif !important;
	font-size: 1em !important;
	font-weight: 300 !important;

	.cc-link{
		padding: 0 !important;
		text-decoration: none !important;
		color: $brand-primary !important;
		opacity: 1 !important;
		border-bottom: $brand-primary 1px dotted;
	}
}
.cc-revoke{
	display: none !important;
}
.cc-btn{
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	font-family: 'Source Sans Pro', sans-serif !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;

	&:hover, &:focus{
		text-decoration: none !important;
	}
	&.cc-deny{
		background: #333 !important;

		&:hover{
			background: lighten(#333, 10%) !important;
		}
	}
	&.cc-dismiss{
		background: $brand-primary !important;

		&:hover{
			background: darken($brand-primary, 10%) !important;
		}
	}
}

@media (min-width: $screen-sm-min) {
	.wrapper{
		min-height: calc(100vh - 60px);
		margin: 30px;

		&:after{
			background: rgba(255, 255, 255, 0.9);
		}
		.header{
			padding: 45px 60px;

			.start-a-project{
				right: 60px;
			}
		}
		.content{
			.btn{
				margin-top: 45px;
			}
		}
		.content-bottom{
			padding: 45px 60px;
		}
	}

	.cc-bottom{
		bottom: 45px !important;
	}
	.cc-right{
		right: 45px !important;
	}
}

@media (min-width: $screen-md-min) {
	.wrapper{
		.content{
			h1{
				font-size: 3.500em;
				font-weight: 200;
			}
			p{
				font-size: 1.850em;
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
}

@media (min-width: 1500px) {
	.container{
		width: 1370px;
	}
}